<template>
  <div class="recommendRetailPrice">
    <template v-if="isSpecialSite">
      <p 
        class="recommendRetailPrice__value" 
        :class="{
          'recommendRetailPrice__gray': !savePriceText
        }"
      >
        {{ recommendRetailPrice.text }}
      </p>
      <del 
        class="recommendRetailPrice__value"
        :class="{
          'recommendRetailPrice__gray': !savePriceText
        }"
      >
        {{ recommendRetailPrice.amount_text }}
      </del>
      <div
        v-if="recommendRetailPrice.discountPercent && !hideDiscount"
        class="recommendRetailPrice__discountPercent"
        :class="{
          'recommendRetailPrice__billion': savePriceText,
          'recommendRetailPrice__flash': isFlashGoods && !savePriceText,
          'special-site-rrp-discount': !savePriceText
        }"
      >
        {{ savePriceText || `-${recommendRetailPrice.discountPercent}%` }}
      </div>
    </template>
    <template v-else>
      <p class="recommendRetailPrice__value">
        {{ recommendRetailPrice.value }}
      </p>
      <div
        v-if="recommendRetailPrice.discountPercent && !hideDiscount"
        class="recommendRetailPrice__discountPercent"
        :class="{
          'recommendRetailPrice__billion': savePriceText,
        }"
      >
        {{ savePriceText || recommendRetailPrice.discountPercent }}
      </div>
    </template>
  </div>
</template>

<script setup name="RecommendRetailPrice">
import { watch, computed } from 'vue'
import { debounce } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { SiteUID } = gbCommonInfo
const props = defineProps({
  recommendRetailPrice: {
    type: Object,
    default: () => ({})
  },
  savePriceText: {
    type: String,
    default: '',
  },
  hideDiscount: {
    type: Boolean,
    default: false
  },
  isFlashGoods: {
    type: Boolean,
    default: false
  },
  isFloorType: {
    type: Boolean,
    default: false
  },
  isQuickAdd: {
    type: Boolean,
    default: false
  }
})

const isSpecialSite = computed(() => ['fr', 'de'].includes(SiteUID))

const debounceExpose = 
debounce({
  func: function () {
    daEventCenter.triggerNotice({
      daId: '1-8-6-256', 
      extraData: {
        recommend_retail_price: props.recommendRetailPrice.usdAmountWithSymbol,
        location: props.isQuickAdd ? 'popup' : 'page'
      }
    })
  },
  wait: 600,
})

watch(
  () => props.recommendRetailPrice?.skuCode,
  val => {
    if (!val) return
    if (props.isFloorType) return
    debounceExpose()
  },
  {
    immediate: true
  }
)

</script>

<style lang="less">
.recommendRetailPrice {

  display: flex;
  align-items: center;

  height: 20px;

  white-space: nowrap;
  font-family: SF Pro;
  font-size: 12px;
  font-weight: 400;
  color: #959595;
  text-align: left;
  del {
    padding-left: 2px;
  }

  &__discountPercent {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 3px;
    height: 16px;
    border: 1px solid #959595;
    margin-left: 4px;
    border-radius: 4px;
    &.special-site-rrp-discount {
      display: flex;
      align-items: flex-start;
      border: 0px;
      padding: 1px 2px;
      border-radius: 2px;
      color: #FFF;
      font-family: Arial;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: #000;
      &.recommendRetailPrice__flash {
        background-color: #FA6338;
        color: #fff;
      }
    }
  }

  &__gray {
    color: #666;
  }

  &__billion {
    color: #959595;
    font-size: 12px;
    border: 0.5px solid #959595;
    border-radius: 2px;
    line-height: 1;
    padding: 1px 2px;
    white-space: nowrap;
  }
  
}
</style>
