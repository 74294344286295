import { transformImg } from '@shein/common-function'
const { IS_RW, IMG_LINK } = gbCommonInfo

/**
 * 获取保留款配置信息
 * @param {String} position // banner / bottom / button
 * @param {String} 腰带内容
 * @returns rightText 右侧显示文字
 */
export const getRetentionConfig = ({ position = '', isSoldOut, tsp = {}, contentText }) => {
  // const num = +(tsp?.['3598'] || '')
  // let text = ''
  // if (num >= 100 && num < 1000) {
  //   text = `${num}`.slice(0, 1) + '00+'
  // } else if (num >= 1000 && num < 9999) {
  //   text = `${num}`.slice(0, 1) + 'k+'
  // } else if (num >= 10000 && num < 99999) {
  //   text = `${num}`.slice(0, 2) + 'k+'
  // } else if (num >= 100000 && num < 999999) {
  //   text = `${num}`.slice(0, 3) + 'k+'
  // } else if (num >= 1000000) {
  //   text = '999k+'
  // }
  const _position = position == 'NO' ? '' : position
  if(isSoldOut || !tsp?.['3598']) return { show: false } // 把ab判断后移并抛出， 因为有些场景不绑定abt
  return {
    // show: !!(_position && !isSoldOut && tsp?.['3598']),
    show: !!_position,
    position: _position,
    rightText: '',
    contentText
  }
}

/**
 * 
 * @param {Object} brandDetail 品牌数据
 * @param {Object} seriesDetail 系列数据
 * @param {Boolean} isBrandStore 是否品牌集成店
 */
export const formatBrandSeriesDetail = async({ brandDetail = {}, seriesDetail = {}, isBrandStore = false, language }) => {
  // 品牌
  const { brand_logo, brand_code, name, brand_introduction } = brandDetail || {}
  const { sc_url: brand_sc_url, sc_url_id: brand_sc_url_id } = brandDetail?.brand_select_info || {}

  // 系列
  const { id, series_name, image_url, series_description, designer_url, designer_id, series_group_name } = seriesDetail || {}
  const { sc_url: series_sc_url, sc_url_id: series_sc_url_id } = seriesDetail?.relate_select?.[0] || {}

  let newBrandDetail = null, newSeriesDetail = null
  const link = !IS_RW ? (designer_url ? designer_url : series_sc_url) : series_sc_url
  const designerId = !IS_RW && designer_url && designer_id
  if (brand_logo || (isBrandStore && name)) { // 品牌
    newBrandDetail = {
      id: brand_code,
      type: 'brand',
      title: language.SHEIN_KEY_PC_17599,
      logo: brand_logo ? transformImg({ img: brand_logo }) : '',
      originLogo: brand_logo,
      name,
      desc: brand_introduction,
      desc_bak: language.SHEIN_KEY_PC_17600,
      link: brand_sc_url,
      scId: brand_sc_url_id,
    }
  } else if (image_url && series_name && series_description && (!IS_RW || link)) { // 系列
    if (
      IS_RW &&
      series_name &&
      series_sc_url &&
      series_group_name === 'ROMWE X 艺术家') {
      newSeriesDetail = {
        id,
        type: 'series',
        title: language.SHEIN_KEY_PC_20343,
        logo: IMG_LINK.romwex,
        name: series_name,
        desc: language.SHEIN_KEY_PC_20333,
        desc_bak: language.SHEIN_KEY_PC_17600,
        link: series_sc_url,
        scId: series_sc_url_id,
      }
    } else {
      newSeriesDetail = {
        id,
        type: 'series',
        title: language.SHEIN_KEY_PC_20343,
        logo: image_url ? transformImg({ img: image_url }) : '',
        name: series_name,
        desc: series_description,
        desc_bak: language.SHEIN_KEY_PC_17600,
        link,
        designerId,
        scId: series_sc_url_id,
      }
    }
  }
  return newBrandDetail || newSeriesDetail
}


/**
 * 图片链接扩展名前添加后缀
 * @param {string} url 图片链接
 * @param {string} suffix 后缀
 * @returns 
 */
export function patchImgUrlSuffix(url, suffix) {
  if (!url || !suffix) return url
  if (url?.indexOf(suffix) !== -1) return url
  // 匹配文件扩展名及其前的查询参数（若存在）
  const regex = /(\.[a-zA-Z0-9]{1,})((\?.*)?)$/

  // 替换操作
  return url.replace(regex, (match, p1, p2) => {
    return suffix + p1 + (p2 ? p2 : '') // 插入字符串，保留文件扩展名和查询参数
  })
}
