<template>
  <SPopover
    class="product-intro__barndBargins-btn"
    placemen="top-start"
    :trigger="trigger"
    :prop-style="{ maxWidth: 'none' }"
    :hide-close-icon="true"
    :append-to-body="true"
    :immediately-render="true"
    :empty-un-show="true"
  >
    <template #reference>
      <SAdapterText
        class="product-intro__barndBargins-adapter"
        lines="2"
        min-size="16"
        :text="btnText"
      />
    </template>
    <div
      v-if="!forbidBuy"
      class="product-intro__barndBargins-qrbox"
    >
      <div :id="qrId"></div>
      <div class="product-intro__barndBargins-iconList">
        <div
          v-for="(item, index) in shopList"
          :key="index"
          class="product-intro__barndBargins-iconitem"
        >
          <img
            :src="item.logoUrl"
            class="product-intro__barndBargins-icon"
          />
          <div class="product-intro__barndBargins-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </SPopover>
</template>

<script setup>
import { defineProps, ref, onMounted, computed, watch } from 'vue'
import { isMobile } from '@shein/common-function'
import { SAdapterText } from '@shein-aidc/sui-adapter-text'
import { SPopover } from '@shein-aidc/sui-popover'
const props = defineProps({
  brandBarginsPromotionInfo: {
    type: Object,
    default: null
  },
  isFromQuick: {
    type: Boolean,
    default: false
  }
})
const qrCode = ref(null)
const qrId = computed(() => {
  return props?.isFromQuick ? 'quick-barndBargins-qrcode' : 'barndBargins-qrcode'
})
const trigger = ref(isMobile() ? 'click' : 'hover')
const shopList = computed(() => props.brandBarginsPromotionInfo?.btnInfo?.shopList || [])
const btnText = computed(() => props.brandBarginsPromotionInfo?.btnInfo?.btnText || '')
const oneLinkUrl = computed(() => props.brandBarginsPromotionInfo?.btnInfo?.oneLinkUrl || '')
const forbidBuy = computed(() => props.brandBarginsPromotionInfo?.btnInfo?.forbidBuy || false)
const qrInit = async () => {
  const QRCode = await import('public/src/js/qrcode.js').then(res => res?.default)
  qrCode.value = new QRCode(qrId.value, {
    text: oneLinkUrl.value,
    width: 140,
    height: 140,
  })
}
watch(oneLinkUrl, (val) => {
  if (val) {
    qrCode.value?.makeCode?.(val)
  }
})
onMounted(() => {
  const timer = setTimeout(() => {
    qrInit()
    clearTimeout(timer)
  })
})
</script>

<style lang="less">
.product-intro {
  &__barndBargins-btn {
    font-size: 18px;
    line-height: normal; 
    height: 54px;
    display: flex !important;
    align-items: center;
  }
  &__barndBargins-adapter {
    max-height: 46px;
    height: auto;
  }
  &__barndBargins-qrbox {
    display: flex;
    align-items: center;
  }
  &__barndBargins-iconList {
    margin-left: 12px;
  }
  &__barndBargins-iconitem {
    border: 1px solid #000;
    width: 232px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
  &__barndBargins-icon {
    height: 16px;
    width: auto;
    margin-right: 5px;
  }
  &__barndBargins-text {
    font-size: 16px;
    font-weight: 700;
    color: #222;
    line-height: 1;
  }
}
</style>
