<template>
  <div
    class="ProductIntroHeadPrice-saveTag__box"
    :class="{ 'ProductIntroHeadPrice-saveTag__right': GB_cssRight }"
  >
    <svg
      class="ProductIntroHeadPrice-saveTag__save-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
    >
      <path
        d="M0 5H5V0C5 2.76142 2.76142 5 0 5Z"
        fill="#FEEFEB"
      />
    </svg>
    <div v-html="priceStr"></div>
  </div>
</template>

<script setup name="BrandBarginsSaveTag">
import { defineProps } from 'vue'
defineProps({
  priceStr: {
    type: String,
    default: ''
  }
})
const { GB_cssRight } = gbCommonInfo || {}
</script>

<style lang="less">
.ProductIntroHeadPrice-saveTag {
  &__right {
    .ProductIntroHeadPrice-saveTag__save-icon {
      transform: scaleX(-1);
    }
  }
  &__box {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 2px 3px;
    border-radius: 2px 2px 2px 0px;
    background: #FEEFEB;
    color: #FE4D00;
    font-size: 12px;
    margin-left: 5px;
    margin-right: 4px;
  }
  &__save-icon {
    position: absolute;
    left: -5px;
    bottom: 0;
  }
  &__save-text {
    margin-right: 2px;
  }
  &__save-num {
    font-weight: 700;
  }
}
</style>
